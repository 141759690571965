import React from 'react'

const CustomModalFooter = ({ onSubmit, label = '' }) => {
   return (
      <div className='border border-[#D0D5DD] footer-wrapper cursor-pointer' onClick={onSubmit}>
         <span>{label}</span>
      </div>
   )
}

export default CustomModalFooter