import React from 'react';

const IconButton = ({ icon = null, title = '', px = true, customIcon }) => {
	return (
		<div
			className={`flex flex-row items-center ${
				px ? 'px-024' : ''
			} pb-2 pt-024 gap-3`}
		>
			{customIcon && <div className="p-2 bg-gray7 rounded-full">
				<div className="bg-black1 p-2 rounded-full">
					<span>{customIcon}</span>
				</div>
			</div>}


			{icon && (
				<div className="p-2 bg-gray7 rounded-full">
					<div className="bg-black1 p-2 rounded-full">
						<img src={icon} alt="icon" />
					</div>
				</div>
			)}
			{title && (
				<div>
					<label className="text-20 font-semibold text-black1">{title}</label>
				</div>
			)}
		</div>
	)
}

export default IconButton
