import { Box, Modal } from '@mui/material'
import React from 'react'
import { boxStyle } from '../../../../constants/types'
import { workActivitiesText } from './index'

import { ReactComponent as ClockIcon } from '../../../../assets/svgs/clock.svg'
import GenericTable from '../../../../components/generic-table/generic-table'

export default function ActivityModal({ selectedWorkActivity, onClose, open }) {
	const { name, department, date, workHours, standUp, hydration } =
		selectedWorkActivity
	const tableColumns = [
		{
			label: 'Time',
			field: 'time',
			align: 'left',
		},
		{
			label: 'Activity',
			field: 'activity',
			align: 'left',
			cellComponent: ({ data }) => {
				return <span>{data}</span>
			},
		},
	]

	const tableData = [
		{
			time: '9:15',
			activity: 'Content Creation',
		},
		{
			time: '11:00',
			activity: 'Break',
		},
		{
			time: '11:15',
			activity: 'Social Media Management',
		},
		{
			time: '13:00',
			activity: 'Lunch',
		},
	]

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box
				sx={{ ...boxStyle, width: 450 }}
				className="outline-none px-024 pb-024"
			>
				<div className="flex items-center gap-3 py-6">
					<div className="bg-[#F9F5FF] rounded-full p-2">
						<div className="bg-[#240B3B] rounded-full p-3">
							<ClockIcon />
						</div>
					</div>
					<span className="text-20 font-semibold text-black1">
						{`${name} ${workActivitiesText('activities')}`}
					</span>
				</div>

				<div className="w-full">
					<GenericTable
						columns={tableColumns}
						data={tableData}
						className="!px-[0px] !py-[0px] !pb-[25px] overflow-x-auto"
					/>
				</div>
				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{workActivitiesText('close_activities')}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
