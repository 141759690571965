import { Box, Modal, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import IconButton from '../../components/common/IconButton';
import { RInputDeclare } from '../../components/custom/RInputDeclare';
import { boxStyle } from '../../constants/types';
import RadioGroupField from '../menu/products/common-components/RadioGroupField';

const GenderEditModal = ({ showModal, data, onClose }) => {
   const [formData, setFormData] = useState({
      gender: data?.gender || '',
      description: data?.description || '',
   });

   useEffect(() => {
      if (data) {
         setFormData({
            gender: data.gender || '',
            description: data.description || '',
         });
      }
   }, [data]);

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
   };

   const handleSubmit = () => {
      if (!formData.gender) {
         NotificationManager.warning("Please fill all the required fields.");
         return;
      }
      onClose(formData);
   };

   return (
      <Modal open={showModal} onClose={() => onClose(false)}>
         <Box sx={{ ...boxStyle, width: 500 }} className="outline-none overflow-auto max-h-[90vh]">
            <IconButton title={data ? "Edit Gender" : "Create Gender"} />
            <div className="flex flex-col p-024 pt-0">
               {data && <RadioGroupField
                  options={[
                     {
                        value: "Anglisht",
                        label: "Anglisht"
                     },
                     {
                        value: "Shqip",
                        label: "Shqip"
                     }]} />}
               <RInputDeclare
                  className="!mb-2 text-base !text-black1 font-medium"
                  value={t('Gender')}
               />
               <TextField
                  size="small"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  placeholder={t('Enter gender')}
               />
               <RInputDeclare
                  className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                  value={t('Description')}
               />
               <TextField
                  size="small"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder={t('Enter description')}
               />
            </div>
            <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
               <button
                  className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                  onClick={() => onClose(false)}
               >
                  {t('Cancel')}
               </button>
               <button
                  className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                  onClick={handleSubmit}
               >
                  {data?.id ? t('Update') : t('Create')}
               </button>
            </div>
         </Box>
      </Modal>
   );
};

export default GenderEditModal;
