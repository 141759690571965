export const HeaderCard = ({ title, count, countType, }) => (
   <div className="flex flex-col flex-1 gap-[10px] p-5 rounded-[12px] bg-white">
      <div className="flex items-center">
         <span className="title">{title}</span>
      </div>
      <div className="flex items-center justify-between">
         <span className="description">{count}</span>
         <span className='text-base ml-1 bg-[#3D205A] text-white w-16 h-8 flex items-center justify-center rounded-12'>
            {countType}
         </span>
      </div>
   </div>
);