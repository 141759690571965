import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { t } from "i18next";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import CustomFormInput from "../../../../components/custom/CustomInput/custom-form-input";
import CustomFormSelect from "../../../../components/custom/CustomSelect/custom-form-select";
import { boxStyle } from "../../../../constants/types";

export default function EditOrderBillingDetailsModal({ order, showModal, onClose, onSubmit }) {

   const splitName = (fullName) => {
      const nameParts = fullName.split(" ");
      return {
         firstName: nameParts[0] || "",
         surname: nameParts.slice(1).join(" ") || "",
      };
   };


   const { firstName = "", surname = "" } = splitName(order.customer.name || "");


   const [formData, setFormData] = useState({
      name: firstName,
      surname: surname,
      city: order.delivery_address.city || "",
      phone: order.customer.phone || "",
      email: order.customer.email || "",
      postalCode: order.delivery_address.postcode || "",
      address: order.delivery_address.address_line1 || "",
   });

   const handleInputChange = (field, value) => {
      setFormData((prevData) => ({
         ...prevData,
         [field]: value,
      }));
   };

   return (
      <div>
         <Modal open={showModal} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ ...boxStyle, width: 600 }} className="outline-none">
               <div className="m-6 !mb-0">
                  <label className="text-20 font-semibold text-black1">Edit Billing</label>
               </div>
               <div className="w-full grid grid-cols-[1fr,1fr] gap-x-6 p-6">
                  <CustomFormInput
                     title={"Name"}
                     value={formData.name}
                     onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                  <CustomFormInput
                     title={"Surname"}
                     value={formData.surname}
                     onChange={(e) => handleInputChange("surname", e.target.value)}
                  />
                  <CustomFormSelect
                     title="City"
                     value={formData.city}
                     onChange={(e) => handleInputChange("city", e.target.value)}
                  />
                  <CustomFormInput
                     title={"Phone no."}
                     value={formData.phone}
                     onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                  <CustomFormInput
                     title={"Email"}
                     value={formData.email}
                     onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <CustomFormInput
                     title={"Postal code"}
                     value={formData.postalCode}
                     onChange={(e) => handleInputChange("postalCode", e.target.value)}
                  />
                  <div className="col-span-2">
                     <CustomFormInput
                        title={"Address"}
                        value={formData.address}
                        onChange={(e) => handleInputChange("address", e.target.value)}
                     />
                  </div>
               </div>
               <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
                  <button
                     className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                     onClick={onClose}
                  >
                     {t("Cancel")}
                  </button>
                  <button
                     className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                     onClick={() => {
                        onClose?.();
                        onSubmit?.(formData); // Pass the formData when submitting
                     }}
                  >
                     {t("common.update")}
                  </button>
               </div>
            </Box>
         </Modal>
      </div>
   );
}
