import React from 'react'

import { t } from 'i18next'
import CustomIconButton from '../../../components/common/IconButton'
import GenericModal from '../../../components/generic-modal'
import GenericTable from '../../../components/generic-table/generic-table'

import CustomModalFooter from '../../../components/generic-table/custom-modal-footer'
import { boxStyle } from '../../../constants/types'
import { breakMonitoringText } from './break-monitoring'
import { breakIcon } from './columns'

const menuText = (text) => t(`components.menus.MenuModal.${text}`)

export default function BreakMonitoringModal({
   showModal,
   onClose,
   rowDetails
}) {

   const dateTimeData = [
      { id: 1, start: '2023-06-03T10:15:00Z', end: '2023-06-03T10:15:00Z' },
      { id: 2, start: '2023-06-03T10:15:00Z', end: '2023-06-03T10:15:00Z' },
      { id: 3, start: '2023-06-03T10:15:00Z', end: '2023-06-03T10:15:00Z' },
      { id: 4, start: '2023-06-03T10:15:00Z', end: '2023-06-03T10:15:00Z' },
      { id: 5, start: '2023-06-03T10:15:00Z', end: '2023-06-03T10:15:00Z' },
   ]

   // Define columns for Start-End Table
   const dateTimeColumns = [
      { field: 'start', label: 'Start', align: 'left' },
      { field: 'end', label: 'End', align: 'left' },
   ]


   return (
      <GenericModal
         {...{ onClose, }}
         open={showModal}
         saveText={menuText('confirm')}
         customIcon={
            <CustomIconButton
               px={false}
               customIcon={breakIcon}
               title={rowDetails?.name + ' Breaks'}
               icon={null}
            />
         }
         footer={<CustomModalFooter onSubmit={onClose} label={breakMonitoringText("closeBreaks")} />}
         sx={{ ...boxStyle, width: 450 }}

      >
         <GenericTable
            data={dateTimeData}
            columns={dateTimeColumns}
            justifyEnd={false}
            className='mt-0 !p-0 !py-2 !pb-4'
         />
      </GenericModal>
   )
}
