import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { t } from "i18next";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import CustomFormSelect from "../../../components/custom/CustomSelect/custom-form-select";
import { boxStyle } from "../../../constants/types";
import { orderTransformer } from "../orders/index";

export const orderStatusOptions = () => Object.entries(orderTransformer).map(([key, { readable }]) => {
   return {
      value: key,
      label: readable
   }
})

export default function UpdateOrderStatusModal({ initialValue, showModal, title, onClose, onSubmit }) {
   const [orderStatus, setOrderStatus] = useState(initialValue);

   return (
      <div>
         <Modal open={showModal} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ ...boxStyle, width: 450 }} className="outline-none">
               <div className="m-6 !mb-0">
                  <label className="text-20 font-semibold text-black1">{title}</label>
               </div>

               <div className="m-6">
                  <CustomFormSelect

                     setOptionValue={setOrderStatus}
                     value={orderStatus}
                     options={orderStatusOptions()}

                  />
               </div>
               <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
                  <button
                     className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                     onClick={onClose}
                  >
                     {t("Cancel")}
                  </button>
                  <button
                     className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                     onClick={() => {
                        onClose?.()
                        onSubmit?.()
                     }}
                  >
                     {t("common.update")}
                  </button>
               </div>
            </Box>
         </Modal>
      </div>
   );
}
