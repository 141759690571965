import { Box, Modal } from '@mui/material'
import React from 'react'
import saleIcon from '../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../components/common/IconButton'
import FooterButtons from '../../components/custom/footer-buttons'
import { boxStyle } from '../../constants/types'
import { devicesViewText } from '../../views/track-devices/devices'

const GenericModal = ({
	open,
	onClose,
	sx = boxStyle,
	title = '',
	children,
	onSubmit,
	loading = false,
	icon = saleIcon,
	saveText = devicesViewText('save'),
	cancelText = devicesViewText('cancel'),
	customIcon,
	disableSave = false,
	footer = null
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={sx} className="outline-none px-024 pb-024">
				{customIcon || (
					<CustomIconButton px={false} icon={icon} title={title} customIcon={customIcon} />
				)}

				{children}

				{footer || <FooterButtons
					onCancel={onClose}
					{...{ saveText, cancelText, onSubmit, loading, disableSave }}
				/>}
			</Box>
		</Modal>
	)
}

export default GenericModal
