import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import HelpfulModal from '../components/helpfulModal'
import ScrollToTop from '../components/Hooks/scrolltoTop'
import { formatVisionTrackUrlPath } from '../utils/common'
import Footer from './Footer'
import './index.css'
import LayoutEmpty from './LayoutEmpty'
import LayoutKDS from './LayoutKDS'
import LayoutPOSFood from './LayoutPOSFood'
import LayoutPOSMobile from './LayoutPOSMobile'
import LayoutPOSMobileRetail from './LayoutPOSMobileRetail'
import LayoutPOSRetail from './LayoutPOSRetail'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

export default function Layout() {
	const { pathname } = useLocation()
	const dashboardAnalytics = pathname.includes('dashboard/analytics')
	const shouldHideSidebarOnKds = pathname.includes('/kds/')
	const shouldHideSidebarOnPos = pathname.includes('/pos/food/cart')
	const shouldHideSidebarOnPosFoodPay =
		pathname.includes('/pos/food/pay')
	const shouldHideSidebarOnPosFoodSuccess =
		pathname.includes('/pos/food/success')
	const shouldHideSidebarOnPosRetailCheckOut =
		pathname.includes('/pos/retail/cart')
	const shouldHideSidebarOnPosRetailPay =
		pathname.includes('/pos/retail/pay')
	const shouldHideSidebarOnPosRetailSuccess = pathname.includes(
		'/pos/retail/success'
	)
	const onPosRetailOrder = pathname.includes('pos/retail/placeorder')
	const onPosFoodOrder = pathname.includes('pos/food/placeorder')
	const isVisionRoute = pathname.includes('vision')
	const isStream = pathname.includes('/stream')

	const [isMobile, setIsMobile] = useState(false)
	const [helpVisible, setHelpVisible] = useState(false)

	const barTitle = useSelector((state) => state.menu.bar)
	const menuTitle = useSelector((state) => state.menu.menu)

	const [scrollTop, setScrollTop] = useState(false)
	const scrollComponentRef = useRef(null)

	useEffect(() => {
		if (window.innerWidth < 768) setIsMobile(true)
		else setIsMobile(false)
	}, [])

	useEffect(() => {
		const scrollComponent = scrollComponentRef.current;
		const handleScroll = () => {
			if (scrollComponent) {
				const newScrollTop = scrollComponent.scrollTop;
				setScrollTop(newScrollTop > 24);
			}
		}

		if (scrollComponent) {
			scrollComponent.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollComponent) {
				scrollComponent.removeEventListener('scroll', handleScroll);
			}
		}
	}, []);


	if (shouldHideSidebarOnKds) {
		return <LayoutKDS />
	} else if (shouldHideSidebarOnPos) {
		return isMobile ? <LayoutPOSMobile /> : <LayoutPOSFood />
	} else if (
		shouldHideSidebarOnPosRetailPay ||
		shouldHideSidebarOnPosRetailSuccess ||
		onPosRetailOrder ||
		onPosFoodOrder ||
		shouldHideSidebarOnPosFoodPay ||
		shouldHideSidebarOnPosFoodSuccess
	) {
		return <LayoutEmpty />
	} else if (shouldHideSidebarOnPosRetailCheckOut) {
		return isMobile ? <LayoutPOSMobileRetail /> : <LayoutPOSRetail />
	} else {
		return (
			<div className="flex flex-col w-full h-full">
				<ScrollToTop />
				<Navbar />
				<div
					className="flex w-full"
					style={{
						display: 'grid',
						gridTemplateColumns: 'auto 1fr',
						height: 'calc(100% - 76px)',
					}}
				>
					<Sidebar />
					<div className="main-body flex-1 flex flex-col justify-between bg-[#F9F7F7] h-full">
						<div
							className={`left inline-flex items-center ${isVisionRoute ? 'ml-[200px] mb-2 mt-6' : (scrollTop ? 'my-6' : 'mt-6')
								}  ${dashboardAnalytics ? 'absolute' : ''} `}
						>
							{barTitle && (
								<div className="text-sm text-[#626769] ml-6">
									{barTitle[0].toUpperCase() + barTitle.slice(1)}
								</div>
							)}
							{menuTitle && (
								<>
									<div className="flex items-center">
										{isVisionRoute ? (
											formatVisionTrackUrlPath(pathname).map((part, index) => (
												<React.Fragment key={index}>
													{/* Always render the '/' before each part */}
													<div className="text-sm text-[#626769] uppercase mx-2">/</div>

													{/* Render the text */}
													<div className="text-sm text-black1">{part}</div>
												</React.Fragment>
											))
										) : (
											<div className="text-sm text-black1">
												{menuTitle[0].toUpperCase() + menuTitle.slice(1)}
												</div>
										)}
									</div>

								</>
							)}
							{isVisionRoute && isStream && (
								<>
									<div className="text-sm text-[#626769] uppercase mx-4">/</div>
									<div className="text-sm text-black1 ">
										Stream
									</div>
								</>
							)}
						</div>
						<div
							className="flex-1 overflow-auto relative"
							ref={scrollComponentRef}
						>
							<Outlet />
							<HelpfulModal
								showModal={helpVisible}
								onClose={() => setHelpVisible(false)}
							/>

						</div>
						<Footer />
					</div>
				</div>
			</div>
		)
	}
}
