import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityOnIcon from '@mui/icons-material/VisibilityOutlined'
import { TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoginService from '../../services/loginService'
import { validateEmailAddress } from '../../utils/common'
import {
	KEYS,
	getAppToken,
	setAppToken,
	setCookieVenueType,
	setLoyaltyProgramId,
	setStorageKey,
	setVenueBasicInfo,
	setVenueShortcode,
} from '../../utils/storage'

import Cookies from 'universal-cookie'
import SvgLoginBgBottom from '../../assets/images/login/login-bg-bottom.svg'
import SvgLoginBgCenter from '../../assets/images/login/login-bg-center.svg'
import SvgLoginBgTop from '../../assets/images/login/login-bg-top.svg'
import logoIcon from '../../assets/svgs/vb-logo-header.png'

import { t } from "i18next"
import { useSelector } from 'react-redux'
import { loadPricingPlanDetails } from '../../store/actions/app'
import {
	SET_IS_TRIAL_MODE,
	SET_PLAN_FEATURES,
	SET_RESTAURANT_LIST,
	SET_SHOW_UPGRADE_FROM_TRIAL,
	SET_SUBSCRIPTION_PLAN,
	SET_VENUE_TYPE,
} from '../../store/actions/types'
import './index.css'

export const loginText = (text) => t(`login.${text}`)

export default function Login(props) {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const cookies = new Cookies()
	const dispatch = useDispatch()

	const [mail, setMail] = useState('')
	const [pass, setPass] = useState('')
	const [checkValid, setCheckValid] = useState(false)
	const [error, setError] = useState(false)
	const [errorInactive, setErrorInactive] = useState(false)

	const [showPassword, setShowPassword] = useState(false)


	const checkValidate = async () => {
		setCheckValid(true)
		let reg = /\S+@\S+\.\S+/

		if (reg.test(mail) && pass !== '') {
			LoginService.login(mail, pass)
				.then(async (res) => {
					if (res?.inactive_message) {
						setErrorInactive(res.inactive_message)
						setError(null)
						return false
					}
					localStorage.setItem(KEYS.is_vision_track, res?.user?.is_vision_track ?? 0)
					dispatch({ type: SET_RESTAURANT_LIST, payload: res.user.restaurants })
					dispatch({
						type: SET_VENUE_TYPE,
						payload: {
							short_code: res.user.restaurants[0].short_code,
							venue_type: res.user.restaurants[0].venue_type.short_name,
						},
					})

					localStorage.setItem('features', JSON.stringify(res?.user?.restaurants?.[0]?.vt_subscription?.plan?.features))

					setAppToken(res.access_token)
					setVenueShortcode(res.user.restaurants[0].short_code)
					setVenueBasicInfo({
						id: res.user.restaurants[0].id,
						name: res.user.restaurants[0].name,
						role: res.user.employee[0].role.name,
						reservation_start_time:
							res.user.restaurants[0].reservation_start_time,
						reservation_end_time: res.user.restaurants[0].reservation_end_time,
						allow_reservation_from:
							res.user.restaurants[0].allow_reservation_from,
						has_hotel_restaurant: res.user.restaurants[0].has_hotel_restaurant,
						plan_id: res.user.restaurants[0].plan_id,
						currency: res.user.restaurants[0].currency,
					})
					// Dispatch and save to storage the new status to update the application's state
					dispatch({
						type: SET_IS_TRIAL_MODE,
						payload: res.user.restaurants[0].subscription.is_trial_mode,
					})
					setStorageKey(
						KEYS.IS_TRIAL_MODE,
						res.user.restaurants[0].subscription.is_trial_mode
					)

					dispatch({
						type: SET_SHOW_UPGRADE_FROM_TRIAL,
						payload:
							res.user.restaurants[0].subscription.show_upgrade_from_trial,
					})
					setStorageKey(
						KEYS.SHOW_UPGRADE_FROM_TRIAL,
						res.user.restaurants[0].subscription.show_upgrade_from_trial
					)
					dispatch({
						type: SET_PLAN_FEATURES,
						payload: res.user.restaurants[0].subscription.features,
					})
					setStorageKey(
						KEYS.PLAN_FEATURES,
						res.user.restaurants[0].subscription.features
					)

					dispatch({
						type: SET_SUBSCRIPTION_PLAN,
						payload: res.user.restaurants[0].subscription.plan,
					})
					setStorageKey(
						KEYS.SUBSCRIPTION_PLAN,
						res.user.restaurants[0].subscription.plan
					)

					setCookieVenueType(res.user.restaurants[0].venue_type.short_name)
					setLoyaltyProgramId(res.user?.restaurants[0]?.loyalty_programs[0]?.id)
					if (res.user?.restaurants[0]?.plan_id) {
						await dispatch(
							loadPricingPlanDetails(res.user?.restaurants[0]?.plan_id)
						)
					}
					navigate(`/${res.user.restaurants[0].short_code}/admin/dashboard`)
				})
				.catch(() => {
					setErrorInactive(null)
					setError(true)
					throw new Error(loginText('error_login'))
				})
		}
	}

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	useEffect(() => {
		if (getAppToken()) {
			if (short_code) {
				navigate(`/${short_code}/admin/dashboard`)
			} else {
				const venue_short_code = cookies.get(KEYS.VENUE_SHORT_CODE)
				navigate(`/${venue_short_code}/admin/dashboard`)
				setVenueShortcode(venue_short_code)
			}
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div className="grid md:grid-cols-2 grid-cols-1 h-[100vh] w-full">
			<div className="flex flex-col md:justify-center justify-start w-full h-full px-8 md:pt-0 md:px-0 md:w-[55%] mx-auto pb-[80px] ">
				<div className="flex flex-row items-center">
					<img
						src={logoIcon}
						alt="logo"
						className='mt-8 md:mt-14'
						style={{ marginBottom: '12px', height: 38 }}
					/>
				</div>
				<div className="flex-1 flex flex-col justify-center w-full ">
					<span className="text-black1 text-[32px] md:text-[48px] font-bold mb-4">
						{loginText('welcome')}
					</span>
					{error && (
						<div className="border-2 border-[#EBA5A3] px-4 py-2 bg-[#F9E2E1] rounded-lg mb-4">
							<span className="text-red1">
								{loginText('error')}
							</span>
						</div>
					)}

					{errorInactive && (
						<div className="border-2 border-[#EBA5A3] px-4 py-2 bg-[#F9E2E1] rounded-lg mb-4">
							<span className="text-red1">{errorInactive}</span>
						</div>
					)}
					<div className="flex flex-col mt-1">
						<span className="text-black1 text-lg font-medium mb-2">{loginText('email')}</span>
						<TextField
							id="filled-required"
							label=""
							placeholder={loginText('email_placeholder')}
							value={mail}
							required
							error={checkValid && !validateEmailAddress(mail)}
							className="w-full login-input"
							onChange={(e) => setMail(e.target.value)}
						/>
					</div>
					<div className="flex flex-col mt-5">
						<span className="text-black1 text-lg font-medium mb-2">
							{loginText('password')}
						</span>
						<TextField
							label=""
							type={showPassword ? 'text' : 'password'}
							placeholder={loginText('password_placeholder')}
							value={pass}
							required
							error={checkValid && pass.length < 6}
							className="w-full login-input"
							onChange={(e) => setPass(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword}>
											{showPassword ? (
												<VisibilityOffIcon />
											) : (
												<VisibilityOnIcon />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>

					<div className="w-full mt-6">
						<button
							className="font-medium text-base text-white bg-cyan3 rounded-lg cursor-pointer h-[48px] px-[20px] my-auto mr-[10px] w-full"
							onClick={checkValidate}
						>
							{loginText('signin')}
						</button>

						<div className="mt-2 flex flex-row flex-wrap">
							<span className="text-gray5 text-[14px]">
								{loginText('privacy_1')}&nbsp;
							</span>
							<a
								href="https://venueboost.io/privacy-policy"
								className="text-black1 underline text-[14px]"
							>
								{loginText('privacy_2')}

							</a>
							<span className="text-gray5 text-[14px]">

								&nbsp;{loginText('privacy_3')}&nbsp;</span>
							<a
								href="https://venueboost.io/terms-conditions"
								className="text-black1 underline text-[14px]"
							>
								{loginText('privacy_4')}

							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden md:block relative">
				<div className="image-container w-full h-full flex flex-col justify-between items-center">
					<img
						src={SvgLoginBgTop}
						alt="login"
						className="w-[80%]  object-contain"
					/>
					<img
						src={SvgLoginBgCenter}
						alt="login"
						className="w-[70%] h-[50%] object-contain"
					/>
					<img
						src={SvgLoginBgBottom}
						alt="login"
						className="w-[50%] object-contain"
					/>
				</div>
			</div>
		</div>
	)
}
