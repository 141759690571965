import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../views/login'
import NotFound from '../views/notFound'
import AuthMiddleware from './authMiddleware'
import Layout from './Layout'

import ReservationsScreen from '../views/reservations'
import ReservationDetailScreen from '../views/reservations/reservationdetail'
import SalesScreen from '../views/sales'
import TablesScreen from '../views/tables'
import TableDetailScreen from '../views/tables/tabledetail'
import { TableListScreen } from '../views/tables/TableListView'

import { useEffect, useState } from 'react'
import Availability from '../components/availability'
import BookingsList from '../views/accommodation/booking/list'
import RentalHousekeeping from '../views/accommodation/rentalHousekeeping'
import RealTimeStatusUpdates from '../views/accommodation/rentalHousekeeping/realTimeStatusUpdates'
import TaskManagement from '../views/accommodation/rentalHousekeeping/taskManagement'
import WorkloadOverview from '../views/accommodation/rentalHousekeeping/workloadOverview'
import RentalUnits from '../views/accommodation/rentalUnit/list'
import RentalUnit from '../views/accommodation/rentalUnit/show'
import Account from '../views/account'
import SettingsStore from '../views/account/store'
import StoreIntegrations from '../views/account/storeIntegrations'
import Shopify from '../views/account/storeIntegrations/shopify'
import WooCommerce from '../views/account/storeIntegrations/wooCommerce'
import StoreSettingsScreen from '../views/account/storeSettings'
import Subscriptions from '../views/account/subscriptions'
import ThemeSelector from '../views/account/themeSelector'
import VenueSpace from '../views/account/venueSpace'
import VenueWebProfileScreen from '../views/account/venueWebProfile'
import AdditionalProfile from '../views/account/venueWebProfile/AdditionalProfile'
import ArticleCategories from '../views/articles/categories'
import ArticleList from '../views/articles/list'
import ArticleView from '../views/articles/list/article-view'
import AddEditUmbrella from '../views/beachbar/add-edit-umbrella'
import BeachbarArea from '../views/beachbar/beachbar-area'
import Tickets from '../views/beachbar/Tickets'
import Umbrellas from '../views/beachbar/umbrellas'
import BestSeller from '../views/best-seller'
import VenueBowlingAvailability from '../views/bowling/availability'
import BowlingFields from '../views/bowling/fields'
import Lanes from '../views/bowling/lanes'
import CitiesScreen from '../views/cities'
import BeachbarConfiguration from '../views/configurations/beachbar-configuration'
import CountriesScreen from '../views/countries'
import CustomBrand from '../views/custom-brand'
import Customers from '../views/customers'
import DashboardAnalytics from '../views/dashboard/analytics/analytics'
import CustomerInsights from '../views/dashboard/insights/insights'
import DeliveryOrders from '../views/delivery/orders'
import CurrenciesScreen from '../views/e-commerce/currencies'
import MenuScreen from '../views/e-commerce/menu'
import PackagingScreen from '../views/e-commerce/packaging'
import PaymentMethodsScreen from '../views/e-commerce/payment-method'
import MenuSubitemsScreen from '../views/e-commerce/sumenu'
import EcommPlatforms from '../views/ecomm-platform/list'
import EmployeesScreen from '../views/employees'
import StaffProfileScreen from '../views/employees/profile'
import ScheduleListScreen from '../views/employees/scheduleList'
import StaffIntegrations from '../views/employees/staffIntegrations'
import QuickBooks from '../views/employees/staffIntegrations/quickBooks'
import EvArcadeGames from '../views/ev-arcade-games/list'
import EvBookings from '../views/ev-bookings/list'
import EvCustomers from '../views/ev-customers/list'
import EvEventManagement from '../views/ev-event-management/list'
import EvExhibits from '../views/ev-exhibits/list'
import EvGuidedTours from '../views/ev-guided-tours/list'
import EvMovieListings from '../views/ev-movie-listings/list'
import EvParkZones from '../views/ev-park-zones/list'
import EvRidesAttractions from '../views/ev-rides-attractions/list'
import EvScreeningRooms from '../views/ev-screening-rooms/list'
import EvSeatingArrangement from '../views/ev-seating-arrangement/list'
import EvShowSchedule from '../views/ev-show-schedule/list'
import EvSkiRentals from '../views/ev-ski-rentals/list'
import EvSlopesAndTrails from '../views/ev-slopes-and-trails/list'
import EvTicketAndPrizes from '../views/ev-ticket-and-prizes/list'
import EvTicketing from '../views/ev-ticketing/list'
import Events from '../views/events'
import GendersScreen from '../views/genders'
import VenueGolfAvailability from '../views/golf/availability'
import CourseTypes from '../views/golf/courseTypes'
import GolfFields from '../views/golf/fields'
import CommunicationPreferencesScreen from '../views/guests/communicationPreferences/index'
import GuestDetailScreen from '../views/guests/guestdetails/index'
import GuestsScreen from '../views/guests/guests'
import VenueGymAvailability from '../views/gym/availability'
import GymClasses from '../views/gym/classes'
import GymFields from '../views/gym/fields'
import HallsAvailability from '../views/hotel/eventHall/availability'
import HallsList from '../views/hotel/eventHall/list'
import GymsAvailability from '../views/hotel/gym/availability'
import GymsList from '../views/hotel/gym/list'
import RestaurantAvailability from '../views/hotel/restaurant/availability'
import RestManage from '../views/hotel/restaurant/index'
import HygieneStandard from '../views/hygiene-standard'
import Ingredient from '../views/ingredient'
import DeliveryPickup from '../views/integrations/deliveryPickup'
import Doordash from '../views/integrations/deliveryPickup/doordash'
import UberEats from '../views/integrations/deliveryPickup/uberEats'
import IntegrationInventory from '../views/integrations/inventory/index'
import Inventory from '../views/inventory'
import Reports from '../views/inventory-management/reports'
import SalesMetrics from '../views/inventory-management/sales-metrics'
import Summary from '../views/inventory-management/summary'
import InventoryActivities from '../views/inventory/activities'
import InventoryDetails from '../views/inventory/details'
import InventoryUsage from '../views/inventory/usage'
import KDSOrders from '../views/KDS/Orders'
import KDSOrdersReady from '../views/KDS/OrdersReady'
import DiningLocationsScreen from '../views/locations'
import GuestsEnrolledScreen from '../views/loyalty/enrolled-guests'
import ProgramsScreen from '../views/loyalty/programs/indes'
import Campaigns from '../views/marketing/campaigns'
import Emails from '../views/marketing/emails'
import EndUserCards from '../views/marketing/endusercards'
import Promotions from '../views/marketing/promotions'
import PromotionsAvailability from '../views/marketing/promotions/availability'
import Referrals from '../views/marketing/referrals'
import VenueWallet from '../views/marketing/wallet'
import NotificationsScreen from '../views/media/notifications'
import PromoScreen from '../views/media/promo'
import SlidersScreen from '../views/media/slider'
import Members from '../views/members'
import AttributesScreen from '../views/menu/attributes'
import AttributeOptionsScreen from '../views/menu/attributes/AttributeOptoinsScreen'
import MenuBrands from '../views/menu/brands'
import MenuCategories from '../views/menu/categories'
import MenuCollections from '../views/menu/collections'
import ConsistentInventory from '../views/menu/consistentInventory'
import Menus from '../views/menu/menus'
import ProductTemplates from '../views/menu/product-template'
import MenuProducts from '../views/menu/products'
import {
	default as AddEditProduct,
	default as CreateProduct,
} from '../views/menu/products/addEdit'
import Messages from '../views/messages'
import OrderAndPayOrders from '../views/orderandpay/orders'
import PaymentLinks from '../views/orderandpay/paymentLinks'
import OrderView from '../views/orders/create-order/create-order'
import OrderDeliveryDetailScreen from '../views/orders/delivery/orderDetails'
import OrderDetailScreen from '../views/orders/orderDetails/orderDetails'
import RetailOrderDetailScreen from '../views/orders/retail/orderDetails'
import PhysicalStores from '../views/physical-store/list'
import PickupOrders from '../views/pickup/orders'
import FoodPay from '../views/POS/FoodPay'
import FoodSuccess from '../views/POS/FoodSuccess'
import PlaceFoodOrderMobile from '../views/POS/PlaceFoodOrderMobile'
import PlaceRetailOrderMobile from '../views/POS/PlaceRetailOrderMobile'
import RetailPay from '../views/POS/RetailPay'
import RetailSuccess from '../views/POS/RetailSuccess'
import AdvancedReport from '../views/report/advanced'
import ReportingFinancial from '../views/report/financialreport/index'
import ReportingOrders from '../views/report/ordersreport/index'
import SalesReport from '../views/report/salesreport'
import StaffReport from '../views/report/staffReport'
import TablesReport from '../views/report/tablesReport'
import WaitlistReport from '../views/report/waitlistReport'
import { AvailableReservation } from '../views/reservations/Available'
import ReservationAvailability from '../views/reservations/ReservationAvailability'
import WaitList from '../views/reservations/waitlist'
import FeaturesByPlan from '../views/settings/featuresByPlan'
import SurveyAndRatings from '../views/surveyAndRatings/index'
import AllSyncs from '../views/sync-management/all-syncs'
import Synchronizations from '../views/sync-management/synchronizations'
import { SeatingArrangementScreen } from '../views/tables/SeatingArrangement'
import VisionTrack from '../views/track-devices'
import AnalyticsDashboard from '../views/track-devices/analytics'
import TrackDashboard from '../views/track-devices/dashboard'
import MonitoringSummary from '../views/track-devices/dashboard/monitoring-summary'
import DevicesScreen from '../views/track-devices/devices'
import ActivityRecognition from '../views/track-devices/environment/activity-recognition/index.jsx'
import SmokeDetection from '../views/track-devices/environment/smoke-detection/smoke-detection'
import MonitorDashboard from '../views/track-devices/monitor'
import MpThreatDetection from '../views/track-devices/security/mp-threat-detection'
import SleepingDetection from '../views/track-devices/security/sleeping-detection/index.jsx'
import ThreatDetection from '../views/track-devices/security/threat-detection'
import SettingsView from '../views/track-devices/settings'
import BehavioralAnalysis from '../views/track-devices/staff/behavioral-analysis'
import BreakMonitoring from '../views/track-devices/staff/break-monitoring'
import SleepDetection from '../views/track-devices/staff/sleep-detection'
import WorkActivities from '../views/track-devices/staff/work-aktivitiy'
import WorkingSessions from '../views/track-devices/staff/working-sessions'
import StreamTable from '../views/track-devices/stream'
import SupportDashboard from '../views/track-devices/support'
import ParkingOcupancy from '../views/track-devices/vehicle/parking-ocupancy/index.jsx'
import ValetOptimisation from '../views/track-devices/vehicle/valet-optimisation/index.jsx'
import VehicleDetection from '../views/track-devices/vehicle/vehicle-detection/index.jsx'
import { VenueListScreen } from '../views/venue'
import { VenueDetailsScreen } from '../views/venue/venueDetails'
import WareHouses from '../views/warehouse/list'
import LayoutPOSFood from './LayoutPOSFood'
import LayoutPOSMobile from './LayoutPOSMobile'
import LayoutPOSMobileRetail from './LayoutPOSMobileRetail'
import LayoutPOSRetail from './LayoutPOSRetail'

const AppRoutes = () => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		if (window.innerWidth < 768) setIsMobile(true)
		else setIsMobile(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerWidth])

	return (
		<React.Fragment>
			<Routes>
				<Route exact path="/" element={<Login />} />
				<Route exact path="/login" element={<Login />} />
				<Route
					path={'/:short_code/admin'}
					element={
						<AuthMiddleware>
							<Layout />
						</AuthMiddleware>
					}
				>
					<Route exact path="dashboard" element={<SalesScreen />} />
					<Route
						exact
						path="dashboard/venue-dashboard"
						element={<SalesScreen />}
					/>
					<Route
						exact
						path="dashboard/analytics"
						element={<DashboardAnalytics />}
					/>
					<Route
						exact
						path="dashboard/insights"
						element={<CustomerInsights />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="staff" element={<Navigate to='/admin/staff/employees' />}/>*/}
					<Route exact path="staff/employees" element={<EmployeesScreen />} />
					<Route
						exact
						path="staff/employees/:id"
						element={<StaffProfileScreen />}
					/>
					<Route
						exact
						path="staff/time-off-requests"
						element={<ScheduleListScreen />}
					/>
					<Route
						exact
						path="staff/staff-integrations"
						element={<StaffIntegrations />}
					/>
					<Route
						exact
						path="staff/staff-integrations/quick-books"
						element={<QuickBooks />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="menu" element={<Navigate to='/admin/menu/menu' />}/>*/}
					<Route exact path="menu/menu" element={<Menus />} />
					<Route exact path="menu/collections" element={<MenuCollections />} />
					<Route exact path="menu/attributes" element={<AttributesScreen />} />
					<Route exact path="menu/attributes/attribute-options/:attributeId" element={<AttributeOptionsScreen />} />
					<Route exact path="menu/categories" element={<MenuCategories />} />
					<Route exact path="menu/inventory" element={<Inventory />} />
					<Route
						exact
						path="menu/consistent-inventory"
						element={<ConsistentInventory />}
					/>
					<Route exact path="ev-booking/bookings" element={<EvBookings />} />
					<Route exact path="customers/customers" element={<EvCustomers />} />
					<Route
						exact
						path="customers/surveys-and-ratings"
						element={<SurveyAndRatings />}
					/>
					<Route
						exact
						path="customers/communication-preferences"
						element={<CommunicationPreferencesScreen />}
					/>
					<Route exact path="manage/arcade-games" element={<EvArcadeGames />} />
					<Route
						exact
						path="manage/ticket-and-prizes"
						element={<EvTicketAndPrizes />}
					/>
					<Route
						exact
						path="manage/event-management"
						element={<EvEventManagement />}
					/>
					<Route exact path="manage/ticketing" element={<EvTicketing />} />
					<Route
						exact
						path="manage/seating-arrangement"
						element={<EvSeatingArrangement />}
					/>
					<Route
						exact
						path="manage/show-schedule"
						element={<EvShowSchedule />}
					/>
					<Route
						exact
						path="manage/rides-attractions"
						element={<EvRidesAttractions />}
					/>
					<Route exact path="manage/park-zones" element={<EvParkZones />} />
					<Route
						exact
						path="manage/slopes-and-trails"
						element={<EvSlopesAndTrails />}
					/>
					<Route exact path="manage/ski-rentals" element={<EvSkiRentals />} />
					<Route exact path="manage/exhibits" element={<EvExhibits />} />
					<Route exact path="manage/guided-tours" element={<EvGuidedTours />} />
					<Route
						exact
						path="manage/movie-listings"
						element={<EvMovieListings />}
					/>
					<Route
						exact
						path="manage/screening-rooms"
						element={<EvScreeningRooms />}
					/>
					<Route exact path="booking/booking" element={<BookingsList />} />
					<Route exact path="rental/units" element={<RentalUnits />} />
					<Route exact path="rental/unit/:id" element={<RentalUnit />} />
					<Route
						exact
						path="rental/housekeeping"
						element={<RentalHousekeeping />}
					/>
					<Route
						exact
						path="rental/housekeeping/real-time-status-updates"
						element={<RealTimeStatusUpdates />}
					/>
					<Route
						exact
						path="rental/housekeeping/workload-overview"
						element={<WorkloadOverview />}
					/>
					<Route
						exact
						path="rental/housekeeping/tasks-management"
						element={<TaskManagement />}
					/>
					<Route
						exact
						path="menu/inventory/:id"
						element={<InventoryDetails />}
					/>
					<Route
						exact
						path="menu/inventory/:id/activity"
						element={<InventoryActivities />}
					/>
					<Route
						exact
						path="menu/inventory/:id/usage"
						element={<InventoryUsage />}
					/>
					<Route exact path="menu/items" element={<MenuProducts />} />

					<Route exact path="menu/brands" element={<MenuBrands />} />
					<Route
						exact
						path="menu/product-templates"
						element={<ProductTemplates />}
					/>
					<Route exact path="menu/items/create" element={<CreateProduct />} />
					<Route
						exact
						path="menu/items/composed/create"
						element={<AddEditProduct isComposed={true} />}
					/>
					<Route
						exact
						path="menu/items/variations/create"
						element={<AddEditProduct isVariations={true} />}
					/>
					<Route exact path="menu/items/edit/:id" element={<CreateProduct />} />

					<Route
						exact
						path="reservation/reservations"
						element={<ReservationsScreen />}
					/>
					<Route
						exact
						path="reservation/reservations/:id"
						element={<ReservationDetailScreen />}
					/>
					<Route exact path="event/events" element={<Events />} />
					<Route exact path="table/tables" element={<TablesScreen />} />
					<Route
						exact
						path="table/tables/listview"
						element={<TableListScreen />}
					/>
					<Route
						exact
						path="reservation/seating-arrangement"
						element={<SeatingArrangementScreen />}
					/>
					<Route
						exact
						path="table/tables/listview/:id"
						element={<TableDetailScreen />}
					/>
					<Route exact path="reservation/waitlist" element={<WaitList />} />
					<Route
						exact
						path="reservation/dining-space-locations"
						element={<DiningLocationsScreen />}
					/>
					<Route
						exact
						path="table/available"
						element={<AvailableReservation />}
					/>
					<Route
						exact
						path="reservation/reservation-calendar"
						element={<ReservationAvailability />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="guests" element={<Navigate to='/admin/guests/guests' />}/>*/}
					<Route exact path="guests/guests" element={<GuestsScreen />} />
					<Route
						exact
						path="guests/guests/:id"
						element={<GuestDetailScreen />}
					/>
					<Route
						exact
						path="guests/communication-preferences"
						element={<CommunicationPreferencesScreen />}
					/>
					<Route
						exact
						path="guests/surveys-and-ratings"
						element={<SurveyAndRatings />}
					/>

					<Route exact path="marketing/promotions" element={<Promotions />} />
					<Route
						exact
						path="marketing/promotions/calendar"
						element={<PromotionsAvailability />}
					/>
					<Route exact path="marketing/emails" element={<Emails />} />
					<Route exact path="marketing/referrals" element={<Referrals />} />
					<Route exact path="marketing/campaigns" element={<Campaigns />} />
					<Route
						exact
						path="marketing/enduser-card"
						element={<EndUserCards />}
					/>
					<Route exact path="marketing/wallet" element={<VenueWallet />} />
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="reporting" element={<Navigate to='/admin/reporting/orders' />}/>*/}
					<Route exact path="reporting/orders" element={<ReportingOrders />} />
					<Route
						exact
						path="reporting/staff-reporting"
						element={<StaffReport />}
					/>
					<Route
						exact
						path="reporting/financial"
						element={<ReportingFinancial />}
					/>
					<Route
						exact
						path="reporting/sales-reporting"
						element={<SalesReport />}
					/>
					<Route
						exact
						path="reporting/waitlist-reporting"
						element={<WaitlistReport />}
					/>
					<Route
						exact
						path="reporting/tables-reporting"
						element={<TablesReport />}
					/>
					<Route exact path="reporting/advanced" element={<AdvancedReport />} />
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="integrations" element={<Navigate to='/admin/integrations/delivery-and-pickup' />} />*/}
					<Route
						exact
						path="integrations/delivery-and-pickup"
						element={<DeliveryPickup />}
					/>
					<Route
						exact
						path="integrations/delivery-and-pickup/ubereats"
						element={<UberEats />}
					/>
					<Route
						exact
						path="integrations/delivery-and-pickup/doordash"
						element={<Doordash />}
					/>
					<Route
						exact
						path="integrations/inventory"
						element={<IntegrationInventory />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="settings" element={<Navigate to='/admin/settings/subscription' />} />*/}
					<Route
						exact
						path="settings/subscription"
						element={<Subscriptions />}
					/>
					<Route
						exact
						path="settings/sales-import"
						element={<SettingsStore />}
					/>

					<Route exact path="settings/cities" element={<CitiesScreen />} />
					<Route exact path="settings/genders" element={<GendersScreen />} />
					<Route exact path="settings/countries" element={<CountriesScreen />} />
					<Route exact path="settings/account" element={<Account />} />
					<Route exact path="settings/space" element={<VenueSpace />} />
					<Route
						exact
						path="settings/web"
						element={<VenueWebProfileScreen />}
					/>
					<Route exact path="settings/web/themes" element={<ThemeSelector />} />
					<Route
						exact
						path="settings/web/additional-information"
						element={<AdditionalProfile />}
					/>
					<Route
						exact
						path="settings/customizable-brand"
						element={<CustomBrand />}
					/>
					<Route
						exact
						path="settings/store-settings"
						element={<StoreSettingsScreen />}
					/>
					<Route
						exact
						path="settings/store-integrations"
						element={<StoreIntegrations />}
					/>
					<Route
						exact
						path="settings/store-integrations/shopify"
						element={<Shopify />}
					/>
					<Route
						exact
						path="settings/store-integrations/woocommerce"
						element={<WooCommerce />}
					/>
					<Route
						exact
						path="settings/features-by-plan"
						element={<FeaturesByPlan />}
					/>
					<Route
						exact
						path="settings/hygiene-standard"
						element={<HygieneStandard />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="loyalty" element={<Navigate to='/admin/loyalty/programs' />} />*/}
					<Route exact path="loyalty/programs" element={<ProgramsScreen />} />
					<Route
						exact
						path="loyalty/guests-enrolled"
						element={<GuestsEnrolledScreen />}
					/>
					<Route
						exact
						path="accommodation/housekeeping"
						element={<RentalHousekeeping />}
					/>
					<Route
						exact
						path="accommodation/housekeeping/real-time-status-updates"
						element={<RealTimeStatusUpdates />}
					/>
					<Route
						exact
						path="accommodation/housekeeping/workload-overview"
						element={<WorkloadOverview />}
					/>
					<Route
						exact
						path="accommodation/housekeeping/tasks-management"
						element={<TaskManagement />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="delivery" element={<Navigate to='/admin/delivery/delivery-orders' />} />*/}
					<Route
						exact
						path="delivery/delivery-orders"
						element={<DeliveryOrders />}
					/>
					<Route
						exact
						path="delivery/delivery-orders/Add"
						element={<OrderView />}
					/>

					<Route
						exact
						path="delivery/surveys-and-ratings"
						element={<SurveyAndRatings />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="pickup" element={<Navigate to='/admin/pickup/pickup-orders' />} />*/}
					<Route exact path="pickup/pickup-orders" element={<PickupOrders />} />
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="delivery" element={<Navigate to='/admin/orders/order/:id' />} />*/}
					<Route
						exact
						path="orders/order/:id"
						element={<OrderDetailScreen />}
					/>
					<Route
						exact
						path="delivery/order/:id"
						element={<OrderDeliveryDetailScreen />}
					/>
					<Route
						exact
						path="retail/order/:id"
						element={<RetailOrderDetailScreen />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="order-and-pay" element={<Navigate to='/admin/order-and-pay/order-and-pay-orders' />} />*/}
					<Route
						exact
						path="order-and-pay/order-and-pay-orders"
						element={<OrderAndPayOrders />}
					/>
					<Route
						exact
						path="order-and-pay/payment-links"
						element={<PaymentLinks />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="golf" element={<Navigate to='/admin/golf/fields' />} />*/}
					<Route exact path="golf/manage" element={<GolfFields />} />
					<Route
						exact
						path="golf/availability"
						element={<VenueGolfAvailability title="Golf" />}
					/>
					<Route
						exact
						path="golf/course-types/availability"
						element={<Availability title="Course" />}
					/>
					<Route exact path="golf/course-types" element={<CourseTypes />} />
					{/*TODO: fix permission after release*/}
					<Route exact path="gym/manage" element={<GymFields />} />
					<Route exact path="gym/classes" element={<GymClasses />} />
					<Route
						exact
						path="gym/availability"
						element={<VenueGymAvailability title="Gym" />}
					/>
					<Route
						exact
						path="gym/classes/availability"
						element={<Availability title="Class" />}
					/>
					<Route exact path="bowling/manage" element={<BowlingFields />} />
					<Route exact path="bowling/lanes" element={<Lanes />} />
					<Route
						exact
						path="bowling/availability"
						element={<VenueBowlingAvailability title="Bowling" />}
					/>
					<Route
						exact
						path="bowling/lanes/availability"
						element={<Availability title="Lane" />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="events-hall" element={<Navigate to='/admin/events-hall/halls-list' />} />*/}
					<Route exact path="events-hall/halls-list" element={<HallsList />} />
					<Route
						exact
						path="events-hall/halls-availability"
						element={<HallsAvailability />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="hotel-gym" element={<Navigate to='/admin/hotel-gym/gyms-list' />} />*/}
					<Route exact path="hotel-gym/gyms-list" element={<GymsList />} />
					<Route
						exact
						path="hotel-gym/gyms-availability"
						element={<GymsAvailability />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="hotel-restaurant" element={<Navigate to='/admin/hotel-restaurant/rest-manage' />} />*/}
					<Route
						exact
						path="hotel-restaurant/rest-manage"
						element={<RestManage />}
					/>
					<Route
						exact
						path="hotel-restaurant/rest-availability"
						element={<RestaurantAvailability />}
					/>
					{/*TODO: fix permission after release*/}
					{/*<Route exact path="venues" element={<Navigate to='/admin/venues/venues' />}/>*/}
					<Route exact path="venues/venues" element={<VenueListScreen />} />
					<Route
						exact
						path="venues/venues/:id"
						element={<VenueDetailsScreen />}
					/>
					<Route
						exact
						path="pos/food/cart"
						element={isMobile ? <LayoutPOSMobile /> : <LayoutPOSFood />}
					/>
					<Route exact path="pos/food/pay" element={<FoodPay />} />
					<Route exact path="pos/food/success" element={<FoodSuccess />} />
					{isMobile && (
						<Route
							exact
							path="pos/food/placeorder"
							element={<PlaceFoodOrderMobile />}
						/>
					)}
					{/*Customer Management*/}
					<Route exact path="customers/all-customers" element={<Customers />} />
					<Route exact path="customers/members" element={<Members />} />

					{/* POS Retail */}
					<Route
						exact
						path={'pos/retail/cart'}
						element={isMobile ? <LayoutPOSMobileRetail /> : <LayoutPOSRetail />}
					/>
					{isMobile && (
						<Route
							exact
							path="pos/retail/placeorder"
							element={<PlaceRetailOrderMobile />}
						/>
					)}
					<Route exact path="pos/retail/pay" element={<RetailPay />} />
					<Route exact path="pos/retail/success" element={<RetailSuccess />} />
					{/* KDS */}
					<Route exact path="kds/orders" element={<KDSOrders />} />
					<Route exact path="kds/orders-ready" element={<KDSOrdersReady />} />

					{/* Beachbar */}
					<Route
						exact
						path="beachbar/umbrella-areas"
						element={<BeachbarArea />}
					/>
					<Route exact path="beachbar/tickets" element={<Tickets />} />
					<Route
						exact
						path="beachbar/areas/:id/umbrellas"
						element={<BeachbarArea isArea={true} />}
					/>

					<Route
						exact
						path="configurations/beachbar-area"
						element={<BeachbarConfiguration />}
					/>

					<Route exact path="beachbar/umbrellas" element={<Umbrellas />} />
					<Route
						exact
						path="beachbar/umbrellas/create"
						element={<AddEditUmbrella />}
					/>
					<Route
						exact
						path="beachbar/umbrellas/edit/:id"
						element={<AddEditUmbrella />}
					/>
					<Route exact path="best-seller" element={<BestSeller />} />

					{/* Brands */}
					<Route exact path="guests/messages" element={<Messages />} />
					<Route exact path="ingredients" element={<Ingredient />} />

					{/* Media */}
					<Route exact path="media/promo" element={<PromoScreen />} />
					<Route exact path="media/notifications" element={<NotificationsScreen />} />
					<Route exact path="media/slider" element={<SlidersScreen />} />

					{/* E-commerce */}
					<Route exact path="e-commerce/menu-list" element={<MenuScreen />} />
					<Route exact path="e-commerce/menu-list/subitems/:menuId" element={<MenuSubitemsScreen />} />
					<Route exact path="e-commerce/currencies" element={<CurrenciesScreen />} />
					<Route exact path="e-commerce/payment-method" element={<PaymentMethodsScreen />} />
					<Route exact path="e-commerce/couriers" element={<div>Couriers</div>} />
					<Route exact path="e-commerce/packaging" element={<PackagingScreen />} />

					{/* sync */}
					<Route
						exact
						path="inventory-management/synchronizations"
						element={<Synchronizations />}
					/>
					<Route
						exact
						path="inventory-management/synchronizations/add"
						element={<AllSyncs />}
					/>
					<Route
						exact
						path="inventory-management/physical-stores"
						element={<PhysicalStores />}
					/>
					<Route
						exact
						path="inventory-management/warehouses"
						element={<WareHouses />}
					/>
					<Route
						exact
						path="inventory-management/e-commerce-platforms"
						element={<EcommPlatforms />}
					/>
					<Route
						exact
						path="inventory-management/reports"
						element={<Reports />}
					/>
					<Route
						exact
						path="inventory-management/reports"
						element={<Reports />}
					/>
					<Route
						exact
						path="inventory-management/summary"
						element={<Summary />}
					/>
					<Route
						exact
						path="inventory-management/sales-metrics"
						element={<SalesMetrics />}
					/>

					{/* Vision track */}

					<Route path={'vision-track'} element={<VisionTrack />}>
						<Route exact path="dashboard" element={<TrackDashboard />} />
						<Route exact path="dashboard/monitoring-summary" element={<MonitoringSummary />} />
						<Route exact path="analytics" element={<AnalyticsDashboard />} />
						<Route exact path="devices" element={<DevicesScreen />} />
						<Route
							exact
							path="devices/:deviceId/stream"
							element={<StreamTable />}
						/>
						{/* <Route exact path="support" element={<SupportDashboard />} /> */}

						{/*Staff*/}
						<Route exact path="staff/working-sessions" element={<WorkingSessions />} />
						<Route exact path="staff/work-activity" element={<WorkActivities />} />
						<Route exact path="staff/sleeping-detection" element={<SleepDetection />} />
						<Route exact path="staff/break-monitoring" element={<BreakMonitoring />} />
						<Route exact path="staff/behavioral-analysis" element={<BehavioralAnalysis />} />

						{/*Environment*/}
						<Route exact path="environment/activity-recognition" element={<ActivityRecognition />} />
						<Route exact path="environment/monitor" element={<MonitorDashboard />} />
						<Route exact path="environment/smoke-detection" element={<SmokeDetection />} />

						{/*Vehicle*/}
						<Route exact path="vehicle/parking-occupancy" element={<ParkingOcupancy />} />
						<Route exact path="vehicle/valet-optimization" element={<ValetOptimisation />} />
						<Route exact path="vehicle/detection" element={<VehicleDetection />} />

						{/*General*/}
						<Route exact path="settings" element={<SettingsView />} />
						{/* <Route exact path="settings/monitoring-parameters" element={<LayoutEmpty />} /> */}

						{/*Security*/}
						<Route exact path="security/multi-point-threat-detection" element={<MpThreatDetection />} />
						<Route exact path="security/sleeping-detection" element={<SleepingDetection />} />
						<Route exact path="security/threat-detection" element={<ThreatDetection />} />

					</Route>

					<Route exact path="articles/list" element={<ArticleList />} />
					<Route exact path="articles/list/add-edit-article" element={<ArticleView />} />
					<Route exact path="articles/categories" element={<ArticleCategories />} />

					<Route exact path="support" element={<SupportDashboard />} />

				</Route>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</React.Fragment>
	)
}

export default AppRoutes
