import { HeatmapOverlay } from "./heat-map-overlay";


const CustomHeatmap = ({ data, width = 600, height = 400, leftLabels, bottomLabels }) => {
   return (
      data && <HeatmapOverlay
         data={data}
         width={width}
         height={height}
         leftLabels={leftLabels}
         bottomLabels={bottomLabels}
      />
   );
};

export default CustomHeatmap;
