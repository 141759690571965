import { API_MEMBERS } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getMembers = async (param) => {
   let api = {
      content_type: 'application/json',
      url: `${API_MEMBERS}`,
      method: 'GET',
   }
   return apiFactory(api, {}, param)
}

const acceptMember = async (payload) => {
   let api = {
      content_type: 'application/json',
      url: `${API_MEMBERS}/accept`,
      method: 'POST',
   }
   return apiFactory(api, payload)
}

const rejectMember = async (payload) => {
   let api = {
      content_type: 'application/json',
      url: `${API_MEMBERS}/reject`,
      method: 'POST',
   }
   return apiFactory(api, payload)
}

export default {
   getMembers,
   rejectMember,
   acceptMember
}
