import React, { useState } from 'react'
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { XAxisLineChart, compactNumberFormatter } from './custom-x-axis-chart'
import { getDomainValue } from './get-domain'
import LineChartTooltip from './tooltips/custom-linechart-tooltip'
import { useXAxisTopSettings } from './use-x-axis'

const CustomLineChart = ({
	data = [],
	dataKey = '',
	lineChartList = [],
	showLegend = true,
	height = 300,
	showTooltip = true,
	orientation = 'left',
	lineType
}) => {
	const [hiddenLines, setHiddenLines] = useState({})
	const [tooltipData, setTooltipData] = useState()

	const xAxisSettings = useXAxisTopSettings(dataKey)

	const handleMouseEnter = (data) => {
		setTooltipData({
			...data,
			payload: [data.payload],
		})
	}

	const handleMouseLeave = () => setTooltipData(undefined)

	const handleLegendClick = (dataKey) => {
		setHiddenLines((prevState) => ({
			...prevState,
			[dataKey]: !prevState[dataKey],
		}))
	}

	return (
		<div className='w-full '>
			<ResponsiveContainer width="100%" height={height}>
				<LineChart data={data} >
					<XAxis {...xAxisSettings} interval={0} tick={XAxisLineChart} />
					{showTooltip && (
						<Tooltip
							content={
								<LineChartTooltip
									name={tooltipData?.name}
									tooltipPayload={tooltipData?.tooltipPayload}
									tooltipData={tooltipData}
								/>
							}
							cursor={false}
						/>
					)}
					{showLegend && (
						<Legend className="cursor-pointer" onClick={handleLegendClick} />
					)}
					<YAxis
						type="number"
						domain={[0, getDomainValue(data)]}
						allowDecimals={false}
						axisLine={false}
						tickLine={false}
						tickFormatter={compactNumberFormatter}
						interval={0}
						tick={true}
						padding={{
							bottom: 0,
							top: 1
						}}
						orientation={orientation}
					/>
					<CartesianGrid vertical={false} horizontal={true} />

					{lineChartList.map((line) => (
						<Line
							key={line.dataKey ?? ''}
							name={line.name}
							dataKey={line.dataKey ?? ''}
							stroke={line.color ?? ''}
							fill={line.color ?? ''}
							dot={{
								fill: line.color ?? '',
								onMouseEnter: handleMouseEnter,
								onMouseLeave: handleMouseLeave,
							}}
							type={lineType}
							strokeWidth={2}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							isAnimationActive={false}
							hide={hiddenLines[line.dataKey]}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}

export default CustomLineChart
