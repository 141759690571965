import { Card, CardContent } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import plusIcon from '../../../assets/svgs/plus.svg';
import AreYouSureModal from '../../../components/common/areYouSureModal';
import GenericPagination from '../../../components/generic-table/generic-pagination';
import GenericTable from '../../../components/generic-table/generic-table';
import { isEmpty } from '../../../utils/common';
import SliderScreenEditModal from './EditSliderModal';
export const sliderText = (text) =>
   t(`components.slider.${text}`);

const SlidersScreen = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [isCreating, setIsCreating] = useState(false);
   const [currentSliderData, setCurrentSliderData] = useState({})
   const [isSliderModelOpen, setSliderModelOpen] = useState(false)
   const [isDeleteModelOpen, setDeleteModelOpen] = useState(false)

   // Dummy data for the table
   const slidersData = [
      {
         id: 1,
         image: 'https://via.placeholder.com/150',
         title: 'Swatch',
         url: '/brand/swatch',
         button: 'Po',
         buttonText: 'Swatch',
         order: 5,
         status: 'Aktiv',
         description: 'Swatch',
      },
      {
         id: 2,
         image: 'https://via.placeholder.com/150',
         title: 'Flik flak',
         url: '/brand/flik-flak',
         button: 'Jo',
         buttonText: 'dsg',
         order: 6,
         status: 'Aktiv',
         description: 'Flik Flak',
      }, {
         id: 2,
         image: 'https://via.placeholder.com/150',
         title: 'Flik flak',
         url: '/brand/flik-flak',
         button: 'Jo',
         buttonText: 'dsg',
         order: 6,
         status: 'Aktiv',
         description: 'Flik Flak',
      }, {
         id: 2,
         image: 'https://via.placeholder.com/150',
         title: 'Flik flak',
         url: '/brand/flik-flak',
         button: 'Jo',
         buttonText: 'dsg',
         order: 6,
         status: 'Aktiv',
         description: 'Flik Flak',
      }
   ];

   const columns = [
      { field: 'id', label: 'No.', align: 'left' },
      {
         field: 'image', label: 'Image', align: 'left', cellComponent: ({ data }) => (
            <div>
               {isEmpty(data) ? (
                  <div className="w-9 h-9 bg-gray6" style={{ borderRadius: '5px' }} />
               ) : (
                  <img
                     src={data}
                     style={{ borderRadius: '5px' }}
                     className="w-9 h-9"
                     alt="preview"
                  />
               )}
            </div>
         ),
      },
      { field: 'title', label: 'Title', align: 'left' },
      { field: 'url', label: 'URL', align: 'left' },
      { field: 'button', label: 'Button', align: 'left' },
      { field: 'buttonText', label: 'Button Text', align: 'left' },
      { field: 'order', label: 'Order', align: 'left' },
      { field: 'status', label: 'Status', align: 'left' },
      { field: 'description', label: 'Description', align: 'left' },
      { field: 'actions', label: 'Actions', align: 'left' },
   ];

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event);
      setPage(0);
   };

   const onNewSlider = () => {
      setIsCreating(true);
      setSliderModelOpen(true)

   };

   const onEdit = (e) => {
      setIsCreating(false)
      setCurrentSliderData(e)
      setSliderModelOpen(true)
   }

   const displayedData = useMemo(() => {
      const start = page * rowsPerPage
      const end = start + rowsPerPage
      return slidersData.slice(start, end)
   }, [slidersData, page, rowsPerPage])

   return (
      <div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
         <div className="flex flex-col h-full p-6">
            <Card>
               <CardContent className="!p-0">
                  <div className="flex flex-row justify-between items-center px-4 pt-[15px]">
                     <div className="flex flex-row items-center">
                        <span className="text-black1 text-xl font-medium">
                           Sliders
                        </span>
                     </div>
                     <button onClick={() => onNewSlider()} className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5'>
                        <img src={plusIcon} alt="plus" /> <span>Create new</span>
                     </button>
                  </div>
                  <div className="flex flex-col overflow-auto">
                     {slidersData.length ? (
                        <>
                           <GenericTable
                              data={displayedData}
                              columns={columns}
                              onEdit={onEdit}
                              onTrash={() => { setDeleteModelOpen(true) }}
                              className='!pt-[15px] !px-4'
                           />
                           <GenericPagination
                              count={slidersData.length}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              setPage={setPage}
                           />
                        </>
                     ) : (
                        <div>No sliders available</div>
                     )}
                  </div>
               </CardContent>
            </Card>
         </div>
         {isSliderModelOpen && <SliderScreenEditModal
            showModal={isSliderModelOpen}
            onClose={() => setSliderModelOpen(false)}
            data={isCreating ? null : currentSliderData}
         />}
         {isDeleteModelOpen && (
            <AreYouSureModal
               title={sliderText('delete_slider_title')}
               content={sliderText('delete_slider_content')}
               firstButton={sliderText('no_go_back')}
               secondButton={sliderText('yes_i_am')}
               showModal={isDeleteModelOpen}
               onClose={() => setDeleteModelOpen(false)}
               onSubmit={() => { }}
            />
         )}
      </div>
   );
};

export default SlidersScreen;
