import { useMemo } from 'react';

export const HeatmapOverlay = ({ data, width, height, leftLabels = '', bottomLabels = '' }) => {
   const colorScale = (value) => {
      const startColor = { r: 120, g: 55, b: 179 };
      const endColor = { r: 237, g: 217, b: 255 };

      const clampedValue = Math.max(0, Math.min(1, value));
      const r = Math.round(startColor.r + clampedValue * (endColor.r - startColor.r));
      const g = Math.round(startColor.g + clampedValue * (endColor.g - startColor.g));
      const b = Math.round(startColor.b + clampedValue * (endColor.b - startColor.b));
      return `rgb(${r}, ${g}, ${b})`;
   };

   const numRows = data.length;
   const numCols = data[0].length;

   const labelPadding = 50;
   const plotWidth = width - (leftLabels ? labelPadding : 0);
   const plotHeight = height - (bottomLabels ? labelPadding : 0);
   const cellWidth = plotWidth / numCols;
   const cellHeight = plotHeight / numRows;

   const shouldRotateLabels = useMemo(() => bottomLabels.length > 5, [bottomLabels]);

   const gridCells = [];
   for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
         const value = data[row][col];
         gridCells.push(
            <rect
               key={`${col}-${row}`}
               x={col * cellWidth + (leftLabels ? labelPadding : 0)}
               y={row * cellHeight}
               width={cellWidth}
               height={cellHeight}
               fill={colorScale(value)}
               opacity={0.5}
            />
         );
      }
   }

   return (
      <div style={{ position: 'relative', width, height }}>

         {/* Left Labels */}
         {leftLabels && (
            <svg style={{ position: 'absolute', top: 0, left: 0, width: labelPadding, height: plotHeight }}>
               {leftLabels.map((label, index) => (
                  <CustomizedTextWithTooltip
                     key={index}
                     x={labelPadding / 2}
                     y={(index + 0.5) * cellHeight}
                     rotateLabel={true}
                     value={label}
                     rotateBy={-45} // Rotating left labels by 45 degrees
                     textAnchor="middle"
                  />
               ))}
            </svg>
         )}

         {/* Bottom Labels */}
         {bottomLabels && (
            <svg style={{ position: 'absolute', bottom: 0, left: leftLabels ? labelPadding : 0, width: plotWidth, height: labelPadding }}>
               {bottomLabels.map((label, index) => (
                  <CustomizedTextWithTooltip
                     key={index}
                     x={(index + 0.5) * cellWidth}
                     y={labelPadding / 2}
                     rotateLabel={shouldRotateLabels}
                     value={label}
                     textWidth={shouldRotateLabels ? 200 : 250}
                  />
               ))}
            </svg>
         )}

         {/* Heatmap Grid */}
         <svg
            style={{
               position: 'absolute',
               top: 0,
               left: leftLabels ? labelPadding : 0,
               width: plotWidth,
               height: plotHeight,
            }}
            viewBox={`0 0 ${plotWidth} ${plotHeight}`}
         >
            {gridCells}
         </svg>
      </div>
   );
};

const CustomizedTextWithTooltip = ({ value, x, y, rotateLabel, rotateBy = 0, textWidth = 120, textAnchor = 'middle' }) => {
   return value ? (
      <g>
         <title>{value}</title>
         <text
            x={x}
            y={y}
            fontSize={rotateLabel ? 10 : 12}
            textAnchor={rotateLabel ? textAnchor : 'middle'}
            transform={rotateLabel ? `rotate(${rotateBy}, ${x}, ${y})` : ''}
            width={textWidth}
            maxLines={rotateLabel ? 1 : 2}
            fill="#666"
         >
            {value}
         </text>
      </g>
   ) : null;
};
